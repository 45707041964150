import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
// import ListFinancial from "./ListFinancial/ListFinancial";
import { POST, GET, GETIBTRANSACTIONBYDATE, GETIBTRANSACTION, SEARCHIBTRANSACTIONTEXT, GETIBTRANSACTIONSUMMARY, GETUSERALL } from "service";
import swal from "sweetalert2";
import moment from "moment";
import Loading from "components/Loading/Loading";
import ListIBTransaction from "./ListIBTransaction/ListIBTransaction";

export class IBTransaction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            date_end: new Date(),
            page: 1,
            count: 0,
            loading: false,
            token: localStorage.getItem("token"),
            role: localStorage.getItem("role"),
            data: [],
            search_text: "",
            reciveIB: [],
            data_csv: [],
            data_select: [],
            data_select_search: '',
            select_search: '',
        };
    }
    componentDidMount = () => {
        if (this.state.role == "admin" || this.state.role == "normal" || this.state.role === "customer_officer") {
            this.getIBTransaction();
            this.getIBTransactionSummary();
            this.getUserAll();
        }
    };

    getUserAll = async () => {
        try {
            // this.setState({ loading: true });
            let res = await GET(GETUSERALL);
            if (res.success) {
                let data = res.result;
                data.unshift(
                    {
                        name: "Search",
                        lastname: null,
                        email: null,
                        user_id: 0,
                    }
                );
                this.setState({ data_select: data })
                // this.setState({ loading: false });
            } else {
                // this.setState({ loading: false });
            }
        } catch (error) {
            console.log(error)
            // this.setState({ loading: false });
        }
    }

    getIBTransactionSummary = async () => {
        let { data_select_search, date_start, date_end } = this.state;
        try {
            // this.setState({ loading: true });
            let ibTransaction = await POST(GETIBTRANSACTIONSUMMARY, { text_search: data_select_search, date_start, date_end });
            if (ibTransaction.success) {
                this.setState({ reciveIB: ibTransaction.result });
                // this.setState({ loading: false });
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: 'กรุณาลองใหม่ภายหลัง', icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                // this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            // this.setState({ loading: false });
        }
    };

    getIBTransaction = async (value) => {
        let { date_start, date_end, data_select_search, page } = this.state;
        try {
            this.setState({ loading: true });
            let ibTransaction = await POST(SEARCHIBTRANSACTIONTEXT, { date_start, date_end, text_search: data_select_search, page: value ? value : page, });
            if (ibTransaction.success) {
                let obj = ibTransaction.result;
                this.setState({ data: obj.data, count: obj.allPage, loading: false });
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: 'กรุณาลองใหม่ภายหลัง', icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };

    getReportIBTransaction = async () => {
        let { date_start, date_end, data_select_search } = this.state;
        try {
            this.setState({ loading: true });
            // let res = await POST(GETIBTRANSACTIONBYDATE, { date_start, date_end });
            let res = await POST(GETIBTRANSACTIONBYDATE, { date_start, date_end, text_search: data_select_search });
            if (res.success) {
                // console.log(res.result);
                this.setState({ download_success: true })
                this.setState({ loading: false });
                return Promise.resolve
                    (
                        Promise.all(res.result.map((el, i) => {
                            return {
                                ib_transaction_id: el.ib_transaction_id,
                                user_id: el.user_id,
                                username: `${el.name} ${el.lastname}`,
                                created_at: moment(el.created_at).format("DD/MM/YYYY HH:mm:ss"),
                                type: el.type === 1 ? "Receive" : "Transfer to wallet",
                                detail: el.detail,
                                receive: el.receive,
                                money: el.type === 2 ? Number(el.money * -1).toFixed(6) : Number(el.money).toFixed(6),
                            }
                        }))
                    )
            } else {
                this.setState({ loading: false });
                swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            console.log(error)
        }
    }

    submitSearchText = async () => {
        this.getIBTransactionSummary();
        this.getIBTransaction();
        this.setState({ data: [] });
    };

    handleSearch = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleChangePage = (e, value) => {
        this.setState({ page: value });
        this.getIBTransaction(value);
    };
    handleDateChange = (d, s, a) => {
        this.setState({ [a]: d });
    };
    handleDateStartChange = (date) => {
        this.setState({ date_start: date });
    };
    handleDateEndChange = (date) => {
        this.setState({ date_end: date });
    };

    handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ download_success: false })
    };

    handleChangeSelect = (value, name) => {
        this.setState({ data_select_search: '' });
        if (value) {
            this.setState({ data_select_search: value.user_id });
        }
        this.setState({ [name]: value });
    }

    render() {
        const language = localStorage.getItem("language");
        const { page, data, loading, reciveIB, data_csv, count, download_success, data_select, select_search } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                <Grid>
                    <ListIBTransaction
                        language={language}
                        data={data}
                        page={page}
                        handleChangePage={this.handleChangePage}
                        state={this.state}
                        // submitSearch={this.submitSearch}
                        handleDateStartChange={this.handleDateStartChange}
                        handleDateEndChange={this.handleDateEndChange}
                        handleSearch={this.handleSearch}
                        submitSearchText={this.submitSearchText}
                        recive={reciveIB}
                        data_csv={data_csv}
                        count={count}
                        getReportIBTransaction={this.getReportIBTransaction}
                        handleCloseAlert={this.handleCloseAlert}
                        download_success={download_success}
                        data_select={data_select}
                        select_search={select_search}
                        handleChangeSelect={this.handleChangeSelect}
                    />
                </Grid>
            </Container>
        );
    }
}
export default withRouter(IBTransaction);
