import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DatePicker from "react-date-picker";
import MonitorChart from "./MonitorChart";
import { useEffect } from "react";

const MonitorCard = (props) => {
    const {
        language,
        state,
        submitSearch,
        handleSelectChange,
        data
    } = props;

    return (
        <Grid container rowSpacing={5} justifyContent="center" alignItems="center" mt={5}>
            {/* Title */}
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                    {language === "th" ? "Monitor Usage" : "Monitor Usage"}
                </Typography>
                <Stack direction="row" alignItems="center" mt={2}>
                    <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
                        {language === "th" ? "ค้นหา" : "Search"}:
                    </Typography>
                    <Grid container xs={12} md={12} lg={10} sx={{ mr: 2 }} spacing={1} display="flex" alignItems="center">
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select Time Period</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.year}
                                    label="Time Period"
                                    onChange={handleSelectChange}
                                >
                                    <MenuItem value={1}>24 Hours</MenuItem>
                                    <MenuItem value={7}>1 Week</MenuItem>
                                    <MenuItem value={30}>1 Month</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} md={3}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#4589C6" }}
                                sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                                onClick={submitSearch}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        submitSearch();
                                    }
                                }}
                            >
                                {language === "th" ? "ค้นหา" : "Search"}
                            </Button>
                        </Grid> */}
                    </Grid>
                </Stack>
            </Grid>

            <Grid item xs={12} >
                <Stack display="flex" width="100%" direction="row" ml="5%">
                    <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", display: { xs: "none", md: "block" }, width: "50%" }}>
                        CRM
                    </Typography>
                    <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", display: { xs: "none", md: "block" }, width: "50%" }}>
                        Database
                    </Typography>
                </Stack>
            </Grid>

            {/* Disk Monitor */}
            <Grid item xs={12} md={0.5}>
                <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
                    Disk
                </Typography>
            </Grid>
            <Grid item xs={12} md={11.5}>
                <MonitorChart
                    title="Disk"
                    data={data}
                    crm={state.crm_disk}
                    database={state.database_disk}
                    period={state.year}
                />
            </Grid>

            {/* CPU Monitor */}
            <Grid item xs={12} md={0.5}>
                <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
                    CPU
                </Typography>
            </Grid>
            <Grid item xs={12} md={11.5}>
                <MonitorChart
                    title="CPU"
                    data={data}
                    crm={state.crm_cpu}
                    database={state.database_cpu}
                    period={state.year}
                />
            </Grid>

            {/* RAM Monitor */}
            <Grid item xs={12} md={0.5}>
                <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
                    RAM
                </Typography>
            </Grid>
            <Grid item xs={12} md={11.5}>
                <MonitorChart
                    title="RAM"
                    data={data}
                    crm={state.crm_ram}
                    database={state.database_ram}
                    period={state.year}
                />
            </Grid>
        </Grid>

    );
};

const data_ = [
    { created_at: "2025-02-20T18:00:00Z", memories: 150 },
    { created_at: "2025-02-20T19:00:00Z", memories: 200 },
    { created_at: "2025-02-20T20:00:00Z", memories: 250 },
    { created_at: "2025-02-20T21:00:00Z", memories: 300 },
    { created_at: "2025-02-20T22:00:00Z", memories: 180 },
    { created_at: "2025-02-20T23:00:00Z", memories: 220 },
    { created_at: "2025-02-21T00:00:00Z", memories: 190 },
    { created_at: "2025-02-21T01:00:00Z", memories: 280 },
    { created_at: "2025-02-21T02:00:00Z", memories: 270 },
    { created_at: "2025-02-21T03:00:00Z", memories: 230 },
    { created_at: "2025-02-21T04:00:00Z", memories: 210 },
    { created_at: "2025-02-21T05:00:00Z", memories: 240 },
    { created_at: "2025-02-21T06:00:00Z", memories: 260 },
];


export default MonitorCard;
