import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
// import ListFinancial from "./ListFinancial/ListFinancial";
import { POST, GET, SEARCHTRANSACTIONMT4, GETTRANSACTIONMT4, SEARCHTRANSACTIONMT4TEXT } from "service";
import swal from "sweetalert2";
import moment from "moment";
import Loading from "components/Loading/Loading";
import ListTransactionMT4 from "./ListTransactionMT4/ListTransactionMT4";

export class TransactionMT4 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date_start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            date_end: new Date(),
            page: 1,
            loading: false,
            token: localStorage.getItem("token"),
            role: localStorage.getItem("role"),
            data: [],
            search_text: "",
            data_csv: [],
        };
    }
    componentDidMount = () => {
        if (this.state.role == "admin" || this.state.role == "normal" || this.state.role === "customer_officer") {
            this.getTransactionMT4();
        }
    };
    getTransactionMT4 = async () => {
        try {
            this.setState({ loading: true });
            let transactionMT4 = await GET(GETTRANSACTIONMT4);
            if (transactionMT4.success) {
                let data_csv = [];
                transactionMT4.result.map((el) => {
                    data_csv.push({
                        transaction_id: el.transaction_id,
                        user_id: el.user_id,
                        username: `${el.name} ${el.lastname}`,
                        email: el.email,
                        created_at: moment(el.created_at).format("DD/MM/YYYY HH:mm:ss"),
                        type: el.type,
                        detail: el.remark,
                        money: Number(el.money).toFixed(6),
                    });
                });
                this.setState({ data: transactionMT4.result, data_csv, loading: false });
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: 'กรุณาลองใหม่ภายหลัง', icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };
    submitSearch = async () => {
        let { date_start, date_end } = this.state;
        try {
            this.setState({ loading: true });
            let transactionMT4 = await POST(SEARCHTRANSACTIONMT4, { date_start, date_end });
            if (transactionMT4.success) {
                let data_csv = [];
                transactionMT4.result.map((el) => {
                    data_csv.push({
                        transaction_id: el.transaction_id,
                        user_id: el.user_id,
                        username: `${el.name} ${el.lastname}`,
                        email: el.email,
                        created_at: moment(el.created_at).format("DD/MM/YYYY HH:mm:ss"),
                        type: el.type,
                        detail: el.remark,
                        money: Number(el.money).toFixed(6),
                    });
                });
                this.setState({ data: transactionMT4.result, data_csv, loading: false });
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: 'กรุณาลองใหม่ภายหลัง', icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };
    submitSearchText = async () => {
        let { search_text, date_start, date_end } = this.state;
        try {
            this.setState({ loading: true });
            let transactionMT4 = await POST(SEARCHTRANSACTIONMT4TEXT, { text_search: search_text, date_start, date_end });
            if (transactionMT4.success) {
                let data_csv = [];
                transactionMT4.result.map((el) => {
                    data_csv.push({
                        transaction_id: el.transaction_id,
                        user_id: el.user_id,
                        username: `${el.name} ${el.lastname}`,
                        email: el.email,
                        created_at: moment(el.created_at).format("DD/MM/YYYY HH:mm:ss"),
                        type: el.type,
                        detail: el.remark,
                        money: Number(el.money).toFixed(6),
                    });
                });
                this.setState({ data: transactionMT4.result, data_csv, loading: false });
            } else {
                swal.fire({
                    title: "ไม่สำเร็จ", text: 'กรุณาลองใหม่ภายหลัง', icon: "warning",
                    // timer: 6000, 
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                // timer: 6000, 
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };
    handleSearch = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };
    handleDateChange = (d, s, a) => {
        this.setState({ [a]: d });
    };

    handleDateStartChange = (date) => {
        this.setState({ date_start: date });
    };
    handleDateEndChange = (date) => {
        this.setState({ date_end: date });
    };

    render() {
        const language = localStorage.getItem("language");
        const { page, data, loading, data_csv } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                <Grid>
                    <ListTransactionMT4
                        language={language}
                        data={data}
                        page={page}
                        handleChangePage={this.handleChangePage}
                        state={this.state}
                        submitSearch={this.submitSearch}
                        handleDateStartChange={this.handleDateStartChange}
                        handleDateEndChange={this.handleDateEndChange}
                        handleSearch={this.handleSearch}
                        submitSearchText={this.submitSearchText}
                        data_csv={data_csv}
                    />
                </Grid>
            </Container>
        );
    }
}
const dataTable = [
    {
        id: 1,
        user: "Username",
        create: "2021-06-13 22:31:45",
        detail: "cashback ticket 73039188 to wallet",
        money: "0.005",
        type: 1,
    },
    {
        id: 2,
        user: "Username",
        create: "2021-06-13 22:31:45",
        detail: "cashback ticket 73039188 to wallet",
        money: "0.005",
        type: 1,
    },
    {
        id: 3,
        user: "Username",
        create: "2021-06-13 22:31:45",
        detail: "cashback ticket 73039188 to wallet",
        money: "0.005",
        type: 1,
    },
    {
        id: 4,
        user: "Username",
        create: "2021-06-13 22:31:45",
        detail: "cashback ticket 73039188 to wallet",
        money: "0.005",
        type: 1,
    },
    {
        id: 5,
        user: "Username",
        create: "2021-06-13 22:31:45",
        detail: "cashback ticket 73039188 to wallet",
        money: "0.005",
        type: 1,
    },
    {
        id: 6,
        user: "Username",
        create: "2021-06-13 22:31:45",
        detail: "cashback ticket 73039188 to wallet",
        money: "0.005",
        type: 1,
    },
];
export default withRouter(TransactionMT4);
