import { Container } from "@material-ui/core";
import moment from "moment";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import MonitorCard from "./components/MonitorCard";
import Loading from "components/Loading/Loading";
import { GETUSAGE, POST } from "service";
import Swal from "sweetalert2";
export class MonitorUsage extends Component {
    constructor(props) {
        super(props);

        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        this.state = {
            loading: false,
            data: [],
            date_end: today,
            year: 1,
            selectedDate: yesterday,
            crm_disk: [],
            database_disk: [],
            crm_cpu: [],
            database_cpu: [],
            crm_ram: [],
            database_ram: [],
        };
    }
    componentDidMount = () => {
        this.submitSearch();
    };

    submitSearch = async (start) => {
        const { date_end, selectedDate } = this.state;
        try {
            this.setState({ loading: true });
            let res = await POST(GETUSAGE, { date_start: start ? start : selectedDate, date_end });
            if (res.success) {
                let crm = res.result.CRM;
                let database = res.result.DATABASE;
                
                this.setState({
                    data: res.result,
                    crm_disk: crm ? crm.DISK : [],
                    database_disk: database ? database.DISK : [],
                    crm_ram: crm ? crm.RAM : [],
                    database_ram: database ? database.RAM : [],
                    crm_cpu: crm ? crm.CPU : [],
                    database_cpu: database ? database.CPU : [],
                });
                
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
                Swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            Swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
        }

    };

    handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        let newDate;

        if (selectedValue === 1) {
            newDate = new Date();
            newDate.setDate(newDate.getDate() - 1);  // 24 hours ahead
        } else if (selectedValue === 7) {
            newDate = new Date();
            newDate.setDate(newDate.getDate() - 7);  // 1 week ahead
        } else if (selectedValue === 30) {
            newDate = new Date();
            newDate.setMonth(newDate.getMonth() - 1);  // Go back 1 month from current date
        }

        this.setState({
            year: selectedValue,
            selectedDate: newDate
        });

        this.submitSearch(newDate);
    };

    render() {
        const language = localStorage.getItem("language");
        const { loading, data } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                <MonitorCard
                    language={language}
                    state={this.state}
                    data={data}
                    submitSearch={this.submitSearch}
                    handleSelectChange={this.handleSelectChange}
                />
            </Container>
        );
    }
}

export default withRouter(MonitorUsage);
