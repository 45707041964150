import React, { Component } from "react";
import { Switch, Redirect } from "react-router-dom";

//-------------------- components -----------------//
import { NotFound } from "../components/index";

//-------------------- layout ---------------------//
import { PageLayout, NotFoundLayout, RouteAndLayout, LoginLayout } from "../layout/index";
import { GET, CHECKSESSION } from "service";

//-------------------- pages ----------------------//
import {
     MainLogin,
     Login,
     Register,
     Dashboard,
     ProfileDetail,
     Members,
     IBList,
     Financial,
     Cashback,
     TransactionMT4,
     ExchangeRate,
     TradingAccounts,
     Inbox,
     Setting,
     SettingNews,
     SettingWalletControl,
     SettingDeposit,
     SettingWithdrawal,
     SettingAddNews,
     SettingPromotion,
     SettingAddPromotion,
     SettingMarket,
     SettingManageMarket,
     SettingManageAccountType,
     SettingAccountType,
     SettingService,
     SettingManageService,
     SettingQuestion,
     SettingQuestionManage,
     SettingPolicy,
     SettingManagePolicy,
     IBDetail,
     IBDetailNest,
     EditRebate,
     BrokerIncome,
     Admin,
     AddAdmin,
     Support,
     SupportDetail,
     TradingReport,
     ManageUser,
     NetworkUser,
     ChangePassword,
     Sale,
     Rebate,
     SettingWithdraw,
     ResetPassCustomer,
     IBTransaction,
     Promotion,
     AddPromotion,
     EditPromotion,
     MT,
     ReportMT5,
     SetupAutoWithdraw,
     EditTypeAccountRebate,
     IBRebateDetail,
     IBRebateList,
     IBRebateCampaign,
     SettingEmail,
     SettingUpdateEmail,
     IBTransfer,
     Manager,
     MamberDetail,
     SalePort,
     TransactionDetail,
     BonusSetting,
     LotDetail,
     SaleManager,
     SettingSaleManager,
     AddSaleTeam,
     SaleManagerMamberDetail,
     SaleManagerTransactionDetail,
     SaleManagerLotDetail,
     MonitorUsage,

     IBRebateDev,/////
     CampaignConfig,/////
     IBTransactionDev,/////
} from "../pages/index";
//-------------------------------------------------//

export default class Routes extends Component {
     constructor(props) {
          super(props);

          this.state = {
               redirect: false,
               data_admin: {},
          };
     }

     componentDidMount() {
          const path = window.location.pathname;
          this.getSession();
          if (
               !path.includes("dashboard") &&
               !path.includes("profile_detail") &&
               !path.includes("iblist") &&
               !path.includes("ibdetail") &&
               !path.includes("editrebate") &&
               !path.includes("trading") &&
               !path.includes("support") &&
               !path.includes("sale") &&
               !path.includes("manage_user") &&
               !path.includes("deleteMT") &&
               !path.includes("financial") &&
               !path.includes("ibrebatelist") &&
               !path.includes("ibrebatecampaign") &&
               !path.includes("accountmanager")
          ) {
               this.setState({ redirect: true });
          }
     }

     getSession = async () => {
          const path = window.location.pathname;
          if (!path.includes("login")) {
               try {
                    let checksession = await GET(CHECKSESSION);
                    if (!checksession.success) {
                         window.location.replace("/login");
                    } else {
                         this.setState({ data_admin: checksession.result });
                    }
               } catch (error) {
                    window.location.replace("/login");
               }
          }
     };

     RouteAdminRole = (redirect, data_admin) => {
          return (
               <>
                    <RouteAndLayout component={Dashboard} exact layout={PageLayout} data_admin={data_admin} path="/dashboard" />
                    <RouteAndLayout component={ProfileDetail} exact layout={PageLayout} data_admin={data_admin} path="/profile_detail/:id?" />
                    <RouteAndLayout component={TradingReport} exact layout={PageLayout} data_admin={data_admin} path="/report_trading/:id?" />
                    <RouteAndLayout component={ManageUser} exact layout={PageLayout} data_admin={data_admin} path="/manage_user" />
                    <RouteAndLayout component={NetworkUser} exact layout={PageLayout} data_admin={data_admin} path="/network_user/:id?" />
                    <RouteAndLayout component={IBList} exact layout={PageLayout} data_admin={data_admin} path="/iblist" />
                    <RouteAndLayout component={IBDetail} exact layout={PageLayout} data_admin={data_admin} path="/ibdetail/:id" />
                    <RouteAndLayout component={IBDetailNest} exact layout={PageLayout} data_admin={data_admin} path="/ibdetailnest/:id" />
                    <RouteAndLayout component={IBRebateDetail} exact layout={PageLayout} data_admin={data_admin} path="/ibrebatedetail/:id" />
                    <RouteAndLayout component={EditRebate} exact layout={PageLayout} data_admin={data_admin} path="/editrebate/:user_id/:user_subid" />
                    <RouteAndLayout component={EditTypeAccountRebate} exact layout={PageLayout} data_admin={data_admin} path="/edittypeaccountrebate/:user_id/:user_subid" />
                    <RouteAndLayout component={TradingAccounts} exact layout={PageLayout} data_admin={data_admin} path="/trading" />
                    <RouteAndLayout component={Support} exact layout={PageLayout} data_admin={data_admin} path="/support" />
                    <RouteAndLayout component={SupportDetail} exact layout={PageLayout} data_admin={data_admin} path="/support/detail/:id?" />
                    <RouteAndLayout component={Members} exact layout={PageLayout} data_admin={data_admin} path="/members" />
                    <RouteAndLayout component={Financial} exact layout={PageLayout} data_admin={data_admin} path="/financial" />
                    <RouteAndLayout component={Cashback} exact layout={PageLayout} data_admin={data_admin} path="/cashback" />
                    <RouteAndLayout component={TransactionMT4} exact layout={PageLayout} data_admin={data_admin} path="/transactionMT4" />
                    <RouteAndLayout component={IBTransaction} exact layout={PageLayout} data_admin={data_admin} path="/ibTransaction" />
                    <RouteAndLayout component={IBTransfer} exact layout={PageLayout} data_admin={data_admin} path="/ibTransfer" />
                    <RouteAndLayout component={ExchangeRate} exact layout={PageLayout} data_admin={data_admin} path="/exchange" />
                    <RouteAndLayout component={Promotion} exact layout={PageLayout} data_admin={data_admin} path="/promotion_list" />
                    <RouteAndLayout component={AddPromotion} exact layout={PageLayout} data_admin={data_admin} path="/promotion_add" />
                    <RouteAndLayout component={EditPromotion} exact layout={PageLayout} data_admin={data_admin} path="/promotion_edit/:id" />
                    <RouteAndLayout component={Setting} exact layout={PageLayout} data_admin={data_admin} path="/setting_banner" />
                    <RouteAndLayout component={SettingNews} exact layout={PageLayout} data_admin={data_admin} path="/setting_news" />
                    <RouteAndLayout component={SettingAddNews} exact layout={PageLayout} data_admin={data_admin} path="/setting_manage_news/:manage/:id?" />
                    <RouteAndLayout component={SettingWalletControl} exact layout={PageLayout} data_admin={data_admin} path="/setting_wallet_control" />
                    <RouteAndLayout component={SettingDeposit} exact layout={PageLayout} data_admin={data_admin} path="/setting_setup_deposit" />
                    <RouteAndLayout component={SettingWithdrawal} exact layout={PageLayout} data_admin={data_admin} path="/setting_setup_withdrawal" />
                    <RouteAndLayout component={SettingPromotion} exact layout={PageLayout} data_admin={data_admin} path="/setting_promotion" />
                    <RouteAndLayout component={SettingAddPromotion} exact layout={PageLayout} data_admin={data_admin} path="/setting_manage_promotion/:manage/:id?" />
                    <RouteAndLayout component={SettingMarket} exact layout={PageLayout} data_admin={data_admin} path="/setting_marketing" />
                    <RouteAndLayout component={SettingManageMarket} exact layout={PageLayout} data_admin={data_admin} path="/setting_manage_market/:manage/:id?" />
                    <RouteAndLayout component={SettingAccountType} exact layout={PageLayout} data_admin={data_admin} path="/setting_account_type" />
                    <RouteAndLayout component={SettingManageAccountType} exact layout={PageLayout} data_admin={data_admin} path="/setting_manage_account_type/:manage/:id?" />
                    <RouteAndLayout component={SettingService} exact layout={PageLayout} data_admin={data_admin} path="/setting_service" />
                    <RouteAndLayout component={SettingManageService} exact layout={PageLayout} data_admin={data_admin} path="/setting_manage_service/:manage/:id?" />
                    <RouteAndLayout component={SettingQuestion} exact layout={PageLayout} data_admin={data_admin} path="/setting_question" />
                    <RouteAndLayout component={SettingQuestionManage} exact layout={PageLayout} data_admin={data_admin} path="/setting_manage_question/:manage/:id?" />
                    <RouteAndLayout component={SettingPolicy} exact layout={PageLayout} data_admin={data_admin} path="/setting_policy" />
                    <RouteAndLayout component={SettingManagePolicy} exact layout={PageLayout} data_admin={data_admin} path="/setting_manage_policy/:manage/:id?" />
                    <RouteAndLayout component={BrokerIncome} exact layout={PageLayout} data_admin={data_admin} path="/broker_income" />
                    <RouteAndLayout component={Admin} exact layout={PageLayout} data_admin={data_admin} path="/admin" />
                    <RouteAndLayout component={AddAdmin} exact layout={PageLayout} data_admin={data_admin} path="/admin/add" />
                    <RouteAndLayout component={Inbox} exact layout={PageLayout} data_admin={data_admin} path="/inbox" />
                    <RouteAndLayout component={ChangePassword} exact layout={PageLayout} data_admin={data_admin} path="/change_password" />
                    <RouteAndLayout component={ResetPassCustomer} exact layout={PageLayout} data_admin={data_admin} path="/reset_password/:id?" />
                    <RouteAndLayout component={Sale} exact layout={PageLayout} data_admin={data_admin} path="/sale" />
                    <RouteAndLayout component={Rebate} exact layout={PageLayout} data_admin={data_admin} path="/rebate" />
                    <RouteAndLayout component={SettingWithdraw} exact layout={PageLayout} data_admin={data_admin} path="/setting_withdraw" />
                    <RouteAndLayout component={SetupAutoWithdraw} exact layout={PageLayout} data_admin={data_admin} path="/setup_auto_withdraw" />
                    <RouteAndLayout component={SettingEmail} exact layout={PageLayout} data_admin={data_admin} path="/setting_email" />
                    <RouteAndLayout component={SettingUpdateEmail} exact layout={PageLayout} data_admin={data_admin} path="/setting_email/edit/:id?" />
                    <RouteAndLayout component={MT} exact layout={PageLayout} data_admin={data_admin} path="/deleteMT" />
                    <RouteAndLayout component={ReportMT5} exact layout={PageLayout} data_admin={data_admin} path="/reportMT5" />
                    <RouteAndLayout component={Manager} exact layout={PageLayout} data_admin={data_admin} path="/manager" />
                    <RouteAndLayout component={MamberDetail} exact layout={PageLayout} data_admin={data_admin} path="/memberdetail/:detail" />
                    <RouteAndLayout component={TransactionDetail} exact layout={PageLayout} data_admin={data_admin} path="/transactiondetail/:detail" />
                    <RouteAndLayout component={BonusSetting} exact layout={PageLayout} data_admin={data_admin} path="/bonus_setting/:id" />
                    <RouteAndLayout component={LotDetail} exact layout={PageLayout} data_admin={data_admin} path="/lotdetail/:detail" />
                    <RouteAndLayout component={SettingSaleManager} exact layout={PageLayout} data_admin={data_admin} path="/setting_sale_manager" />
                    <RouteAndLayout component={AddSaleTeam} exact layout={PageLayout} data_admin={data_admin} path="/setting_sale_manager/edit/:id?" />
                    <RouteAndLayout component={MonitorUsage} exact layout={PageLayout} data_admin={data_admin} path="/monitor_usage" />

                    {/* <RouteAndLayout component={IBRebateList} exact layout={PageLayout} data_admin={data_admin} path="/ibrebatelist" />
                    <RouteAndLayout component={IBRebateCampaign} exact layout={PageLayout} data_admin={data_admin} path="/IBRebateCampaign/:id" /> */}

                    {/* REBATE NEW */}
                    <RouteAndLayout component={IBRebateDev} exact layout={PageLayout} data_admin={data_admin} path="/ibrebatelist" />
                    <RouteAndLayout component={CampaignConfig} exact layout={PageLayout} data_admin={data_admin} path="/campaignconfig/:id" />


                    {/* <RouteAndLayout component={IBRebateDev} exact layout={PageLayout} data_admin={data_admin} path="/ibrebateDev" />
                    <RouteAndLayout component={CampaignConfig} exact layout={PageLayout} data_admin={data_admin} path="/campaignconfig/:id" />
                    <RouteAndLayout component={IBTransactionDev} exact layout={PageLayout} data_admin={data_admin} path="/ibTransactiondev" /> */}
               </>
          );
     };

     RouteSaleRole = (redirect, data_admin) => {
          return (
               <>
                    <RouteAndLayout component={Dashboard} exact layout={PageLayout} data_admin={data_admin} path="/dashboard" />
                    <RouteAndLayout component={ProfileDetail} exact layout={PageLayout} data_admin={data_admin} path="/profile_detail/:id" />
                    <RouteAndLayout component={TradingReport} exact layout={PageLayout} data_admin={data_admin} path="/report_trading/:id?" />
                    <RouteAndLayout component={ManageUser} exact layout={PageLayout} data_admin={data_admin} path="/manage_user" />
                    <RouteAndLayout component={NetworkUser} exact layout={PageLayout} data_admin={data_admin} path="/network_user/:id?" />
                    <RouteAndLayout component={IBList} exact layout={PageLayout} data_admin={data_admin} path="/iblist" />
                    <RouteAndLayout component={IBDetailNest} exact layout={PageLayout} data_admin={data_admin} path="/ibdetailnest/:id" />
                    <RouteAndLayout component={IBDetail} exact layout={PageLayout} data_admin={data_admin} path="/ibdetail/:id" />
                    <RouteAndLayout component={EditRebate} exact layout={PageLayout} data_admin={data_admin} path="/editrebate/:id" />
                    <RouteAndLayout component={Financial} exact layout={PageLayout} data_admin={data_admin} path="/financial" />
                    <RouteAndLayout component={TradingAccounts} exact layout={PageLayout} data_admin={data_admin} path="/trading" />
                    <RouteAndLayout component={Support} exact layout={PageLayout} data_admin={data_admin} path="/support" />
                    <RouteAndLayout component={SupportDetail} exact layout={PageLayout} data_admin={data_admin} path="/support/detail/:id?" />
                    <RouteAndLayout component={ChangePassword} exact layout={PageLayout} data_admin={data_admin} path="/change_password" />
                    <RouteAndLayout component={Sale} exact layout={PageLayout} data_admin={data_admin} path="/sale" />
                    <RouteAndLayout component={SalePort} exact layout={PageLayout} data_admin={data_admin} path="/sale/port/:id" />
                    <RouteAndLayout component={Rebate} exact layout={PageLayout} data_admin={data_admin} path="/rebate" />
                    <RouteAndLayout component={ResetPassCustomer} exact layout={PageLayout} data_admin={data_admin} path="/reset_password/:id?" />
                    {/* <RouteAndLayout component={IBRebateList} exact layout={PageLayout} data_admin={data_admin} path="/ibrebatelist" />
                    <RouteAndLayout component={IBRebateCampaign} exact layout={PageLayout} data_admin={data_admin} path="/IBRebateCampaign/:id" /> */}
                    <RouteAndLayout component={IBRebateDev} exact layout={PageLayout} data_admin={data_admin} path="/ibrebatelist" />
                    <RouteAndLayout component={CampaignConfig} exact layout={PageLayout} data_admin={data_admin} path="/campaignconfig/:id" />
                    {redirect && <Redirect to="/login" />}
               </>
          );
     };

     RouteNormalRole = (redirect, data_admin) => {
          return (
               <>
                    <RouteAndLayout component={Dashboard} exact layout={PageLayout} data_admin={data_admin} path="/dashboard" />
                    <RouteAndLayout component={ProfileDetail} exact layout={PageLayout} data_admin={data_admin} path="/profile_detail/:id" />
                    <RouteAndLayout component={TradingReport} exact layout={PageLayout} data_admin={data_admin} path="/report_trading/:id?" />
                    <RouteAndLayout component={ManageUser} exact layout={PageLayout} data_admin={data_admin} path="/manage_user" />
                    <RouteAndLayout component={IBList} exact layout={PageLayout} data_admin={data_admin} path="/iblist" />
                    <RouteAndLayout component={IBDetailNest} exact layout={PageLayout} data_admin={data_admin} path="/ibdetailnest/:id" />
                    <RouteAndLayout component={IBDetail} exact layout={PageLayout} data_admin={data_admin} path="/ibdetail/:id" />
                    <RouteAndLayout component={Financial} exact layout={PageLayout} data_admin={data_admin} path="/financial" />
                    <RouteAndLayout component={TransactionMT4} exact layout={PageLayout} data_admin={data_admin} path="/transactionMT4" />
                    <RouteAndLayout component={IBTransaction} exact layout={PageLayout} data_admin={data_admin} path="/ibTransaction" />
                    <RouteAndLayout component={Support} exact layout={PageLayout} data_admin={data_admin} path="/support" />
                    <RouteAndLayout component={SupportDetail} exact layout={PageLayout} data_admin={data_admin} path="/support/detail/:id?" />
                    <RouteAndLayout component={ResetPassCustomer} exact layout={PageLayout} data_admin={data_admin} path="/reset_password/:id?" />
                    <RouteAndLayout component={NetworkUser} exact layout={PageLayout} data_admin={data_admin} path="/network_user/:id?" />
                    <RouteAndLayout component={MT} exact layout={PageLayout} data_admin={data_admin} path="/deleteMT" />
                    <RouteAndLayout component={ReportMT5} exact layout={PageLayout} data_admin={data_admin} path="/reportMT5" />
                    <RouteAndLayout component={BonusSetting} exact layout={PageLayout} data_admin={data_admin} path="/bonus_setting/:id" />
                    {/* <RouteAndLayout component={IBRebateList} exact layout={PageLayout} data_admin={data_admin} path="/ibrebatelist" />
                    <RouteAndLayout component={IBRebateCampaign} exact layout={PageLayout} data_admin={data_admin} path="/IBRebateCampaign/:id" /> */}
                    <RouteAndLayout component={IBRebateDev} exact layout={PageLayout} data_admin={data_admin} path="/ibrebatelist" />
                    <RouteAndLayout component={CampaignConfig} exact layout={PageLayout} data_admin={data_admin} path="/campaignconfig/:id" />
                    {redirect && <Redirect to="/login" />}
               </>
          );
     };

     RouteCustomerOfficerRole = (redirect, data_admin) => {
          return (
               <>
                    <RouteAndLayout component={Dashboard} exact layout={PageLayout} data_admin={data_admin} path="/dashboard" />
                    <RouteAndLayout component={ProfileDetail} exact layout={PageLayout} data_admin={data_admin} path="/profile_detail/:id" />
                    <RouteAndLayout component={TradingReport} exact layout={PageLayout} data_admin={data_admin} path="/report_trading/:id?" />
                    <RouteAndLayout component={ManageUser} exact layout={PageLayout} data_admin={data_admin} path="/manage_user" />
                    <RouteAndLayout component={IBList} exact layout={PageLayout} data_admin={data_admin} path="/iblist" />
                    <RouteAndLayout component={IBDetailNest} exact layout={PageLayout} data_admin={data_admin} path="/ibdetailnest/:id" />
                    <RouteAndLayout component={IBDetail} exact layout={PageLayout} data_admin={data_admin} path="/ibdetail/:id" />
                    <RouteAndLayout component={Financial} exact layout={PageLayout} data_admin={data_admin} path="/financial" />
                    <RouteAndLayout component={TransactionMT4} exact layout={PageLayout} data_admin={data_admin} path="/transactionMT4" />
                    <RouteAndLayout component={Support} exact layout={PageLayout} data_admin={data_admin} path="/support" />
                    <RouteAndLayout component={SupportDetail} exact layout={PageLayout} data_admin={data_admin} path="/support/detail/:id?" />
                    <RouteAndLayout component={ResetPassCustomer} exact layout={PageLayout} data_admin={data_admin} path="/reset_password/:id?" />
                    <RouteAndLayout component={NetworkUser} exact layout={PageLayout} data_admin={data_admin} path="/network_user/:id?" />
                    <RouteAndLayout component={MT} exact layout={PageLayout} data_admin={data_admin} path="/deleteMT" />
                    <RouteAndLayout component={ReportMT5} exact layout={PageLayout} data_admin={data_admin} path="/reportMT5" />
                    <RouteAndLayout component={BonusSetting} exact layout={PageLayout} data_admin={data_admin} path="/bonus_setting/:id" />
                    {redirect && <Redirect to="/login" />}
               </>
          );
     };

     RouteSaleManagerRole = (redirect, data_admin) => {
          return (
               <>
                    <RouteAndLayout component={Dashboard} exact layout={PageLayout} data_admin={data_admin} path="/dashboard" />
                    <RouteAndLayout component={ProfileDetail} exact layout={PageLayout} data_admin={data_admin} path="/profile_detail/:id" />
                    <RouteAndLayout component={TradingReport} exact layout={PageLayout} data_admin={data_admin} path="/report_trading/:id?" />
                    <RouteAndLayout component={ManageUser} exact layout={PageLayout} data_admin={data_admin} path="/manage_user" />
                    <RouteAndLayout component={NetworkUser} exact layout={PageLayout} data_admin={data_admin} path="/network_user/:id?" />
                    <RouteAndLayout component={IBList} exact layout={PageLayout} data_admin={data_admin} path="/iblist" />
                    <RouteAndLayout component={IBDetailNest} exact layout={PageLayout} data_admin={data_admin} path="/ibdetailnest/:id" />
                    <RouteAndLayout component={IBDetail} exact layout={PageLayout} data_admin={data_admin} path="/ibdetail/:id" />
                    <RouteAndLayout component={EditRebate} exact layout={PageLayout} data_admin={data_admin} path="/editrebate/:id" />
                    <RouteAndLayout component={Financial} exact layout={PageLayout} data_admin={data_admin} path="/financial" />
                    <RouteAndLayout component={TradingAccounts} exact layout={PageLayout} data_admin={data_admin} path="/trading" />
                    <RouteAndLayout component={Support} exact layout={PageLayout} data_admin={data_admin} path="/support" />
                    <RouteAndLayout component={SupportDetail} exact layout={PageLayout} data_admin={data_admin} path="/support/detail/:id?" />
                    <RouteAndLayout component={ChangePassword} exact layout={PageLayout} data_admin={data_admin} path="/change_password" />
                    <RouteAndLayout component={Sale} exact layout={PageLayout} data_admin={data_admin} path="/sale" />
                    <RouteAndLayout component={SalePort} exact layout={PageLayout} data_admin={data_admin} path="/sale/port/:id" />
                    <RouteAndLayout component={Rebate} exact layout={PageLayout} data_admin={data_admin} path="/rebate" />
                    <RouteAndLayout component={ResetPassCustomer} exact layout={PageLayout} data_admin={data_admin} path="/reset_password/:id?" />
                    {/* <RouteAndLayout component={IBRebateList} exact layout={PageLayout} data_admin={data_admin} path="/ibrebatelist" />
                    <RouteAndLayout component={IBRebateCampaign} exact layout={PageLayout} data_admin={data_admin} path="/IBRebateCampaign/:id" /> */}
                    <RouteAndLayout component={IBRebateDev} exact layout={PageLayout} data_admin={data_admin} path="/ibrebatelist" />
                    <RouteAndLayout component={CampaignConfig} exact layout={PageLayout} data_admin={data_admin} path="/campaignconfig/:id" />
                    <RouteAndLayout component={SaleManager} exact layout={PageLayout} data_admin={data_admin} path="/accountmanager" />
                    <RouteAndLayout component={SaleManagerMamberDetail} exact layout={PageLayout} data_admin={data_admin} path="/accountmanager/memberdetail/:detail" />
                    <RouteAndLayout component={SaleManagerTransactionDetail} exact layout={PageLayout} data_admin={data_admin} path="/accountmanager/transactiondetail/:detail" />
                    <RouteAndLayout component={SaleManagerLotDetail} exact layout={PageLayout} data_admin={data_admin} path="/accountmanager/lotdetail/:detail" />
                    {redirect && <Redirect to="/login" />}
               </>
          );
     };

     render() {
          const { redirect, data_admin } = this.state;
          const role = localStorage.getItem("role");
          return (
               <Switch>
                    <Redirect exact from="/" to="/login" />
                    {/* <RouteAndLayout component={MainLogin} exact layout={LoginLayout} path="/mainlogin" /> */}
                    <RouteAndLayout component={Login} exact layout={LoginLayout} path="/login" />
                    {/* <RouteAndLayout component={Register} exact layout={LoginLayout} path="/register" /> */}
                    {/*======================================================================================*/}
                    {/* <RouteAndLayout component={Main} exact layout={MainLayout} path="/main" /> */}
                    {role === "admin" ? (
                         this.RouteAdminRole(redirect, data_admin)
                    ) : role === "sale" ? (
                         this.RouteSaleRole(redirect, data_admin)
                    ) : role === "normal" ? (
                         this.RouteNormalRole(redirect, data_admin)
                    ) : role === "customer_officer" ? (
                         this.RouteCustomerOfficerRole(redirect, data_admin)
                    ) : role === "sale_manager" ? (
                         this.RouteSaleManagerRole(redirect, data_admin)
                    ) : (
                         <Redirect to="/not-found" />
                    )}
                    {/* <RouteAndLayout component={Policy} exact layout={PageLayout} path="/policy" /> */}
                    {/* <RouteAndLayout component={Question} exact layout={PageLayout} path="/question" /> */}
                    {/* <RouteAndLayout component={Contact} exact layout={ContactLayout} path="/contact" /> */}
                    <RouteAndLayout component={NotFound} exact layout={NotFoundLayout} path="/not-found" />
                    <Redirect to="/not-found" />
               </Switch>
          );
     }
}
