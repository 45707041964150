import { Container, Grid } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { ADDCAMPAIGNDEV, DELETECAMPAIGNDEV, GETCAMPAIGNDEV, GETCAMPAIGNSEARCHDEV, GETDATAEDITCAMPAIGNDEV, GETDATAEDITCAMPAIGNSELFDEV, GETIBACCOUNTCAMPAIGN, GETSELECTCAMPAIGN, POST, PROFILEBYUSER } from "service";
import Swal from "sweetalert2";
import ListIBCampaign from "./IBCampaign/ListIBCampaign";

export class IBCampaign extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            data: [],
            user_id: this.props.match.params.id,
            data_select: [],
            select_name_ib: '',
            nav_name_ib: [],
            data_tbl_rebate: [],
            data_tbl_manage: {
                head_tbl: [],
                data_tbl: [],
            },
            enable_add: false,
            enable_edit: false,
            enable_self_edit: false,
            search_text: "",
            data_tbl_view: {
                head_tbl: [],
                data_tbl: [],
            },
            view_campaign: false,
            data_self_tbl: {
                head_tbl: [],
                data_tbl: [],
            },
        };
    }
    componentDidMount = () => {
        this.getIbAccountType();
        this.getCampaign();
    };

    getProfileById = async () => {
        const { user_id, nav_name_ib, data_tbl_manage } = this.state;
        try {
            this.setState({ loading: true });
            let res = await POST(PROFILEBYUSER, { user_id });
            if (res.success) {
                const { name, lastname, email, user_id } = res.result;
                let rebate = [];

                nav_name_ib.push({ name, lastname, email, user_id })
                data_tbl_manage.head_tbl.forEach(element => {
                    rebate.push({ forex: 0, gold: 0, crypto: 0, index: 0, oil: 0, account_type_id: element.account_type_id });
                });

                this.setState({ data_tbl_manage: { ...data_tbl_manage, data_tbl: [...data_tbl_manage.data_tbl, { name, lastname, email, user_id, rebate }] } });
                this.handleCreateSelfRebate([...data_tbl_manage.data_tbl, { name, lastname, email, user_id, rebate }]);
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false });
        }
    }

    getSelectCampaign = async (user_id) => {
        try {
            this.setState({ loading: true });
            let res = await POST(GETSELECTCAMPAIGN, { user_id });
            if (res.success) {
                this.setState({ data_select: res.result, enable_add: true, select_name_ib: '' })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false });
        }
    }

    getIbAccountType = async () => {
        const { data_tbl_manage } = this.state;
        try {
            this.setState({ loading: true });
            let res = await POST(GETIBACCOUNTCAMPAIGN, { user_id: this.props.match.params.id });
            if (res.success) {
                this.setState({ data_tbl_manage: ({ ...data_tbl_manage, head_tbl: res.result }) })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false });
        }
    }

    getCampaign = async () => {
        try {
            this.setState({ loading: true });
            let res = await POST(GETCAMPAIGNDEV, { user_id: this.props.match.params.id });
            if (res.success) {
                // console.log(res.result);
                this.setState({ data_tbl_rebate: res.result })
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false });
        }
    }

    handleEditCampaign = async (user_id, header, campaign) => {
        const { data_tbl_manage, data_self_tbl } = this.state;
        try {
            this.setState({ loading: true });
            let res = await POST(GETDATAEDITCAMPAIGNDEV, { user_id, header, campaign });
            if (res.success) {
                // console.log(res.result);
                const { nav_name_ib, data_tbl, data_self_rebate_tbl, head_tbl } = res.result
                // console.log(Number(nav_name_ib.at(-1).user_id))

                // this.getSelectCampaign(Number(nav_name_ib.at(-1).user_id))
                let rebate = [];
                let obj = data_tbl.at(-1)

                if (data_self_rebate_tbl.length <= 0) {
                    let { name, lastname, email, user_id } = obj;

                    head_tbl.forEach(element => {
                        rebate.push({ forex: 0, gold: 0, crypto: 0, index: 0, oil: 0, account_type_id: element.account_type_id });
                    });

                    this.setState({ data_self_tbl: { data_tbl: [{ name, lastname, email, user_id, rebate }], head_tbl: head_tbl } });

                } else {
                    this.setState({ data_self_tbl: ({ data_tbl: data_self_rebate_tbl, head_tbl: head_tbl }) });
                }
                this.setState({ nav_name_ib: nav_name_ib, data_tbl_manage: ({ ...data_tbl_manage, data_tbl: data_tbl, head_tbl: head_tbl }) });
                this.setState({ loading: false, enable_self_edit: false, enable_edit: true, enable_add: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false });
        }
    }

    handleEditCampaignSelfRebate = async (user_id, header, campaign) => {
        // const { data_self_tbl } = this.state;
        try {
            this.setState({ loading: true });
            let res = await POST(GETDATAEDITCAMPAIGNSELFDEV, { user_id, header, campaign });
            if (res.success) {
                const { nav_name_ib, data_self_rebate_tbl, head_tbl } = res.result

                this.setState({ nav_name_ib: nav_name_ib, data_self_tbl: ({ data_tbl: data_self_rebate_tbl, head_tbl: head_tbl }) });
                this.setState({ loading: false, enable_self_edit: true, enable_edit: false, enable_add: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false });
        }
    }

    handleViewCampaign = async (user_id, header, campaign, type) => {
        try {
            this.setState({ loading: true });
            const api_ = type ? GETDATAEDITCAMPAIGNSELFDEV : GETDATAEDITCAMPAIGNDEV;
            let res = await POST(api_, { user_id, header, campaign });
            if (res.success) {
                const { nav_name_ib, data_tbl, data_self_rebate_tbl, head_tbl } = res.result
                this.setState({ nav_name_ib: nav_name_ib, data_tbl_view: ({ data_tbl: type ? data_self_rebate_tbl : data_tbl, head_tbl: head_tbl }), view_campaign: true });
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false });
        }
    }

    handleChangePage = (e, value) => {
        this.setState({ page: value });
    };

    handleEnableAdd = () => {
        const { user_id } = this.state;

        this.getSelectCampaign(user_id);
        this.getProfileById();
        this.setState({ enable_add: true });
    };

    handleCloseView = () => {
        this.setState({ view_campaign: false });
    };

    handleChangeSelect = (e) => {
        const { nav_name_ib, data_tbl_manage } = this.state;
        const { name, lastname, email, user_id } = e.target.value;
        let rebate = [];
        if (user_id) {
            this.getSelectCampaign(user_id)
        }
        data_tbl_manage.head_tbl.forEach(element => {
            rebate.push({ forex: 0, gold: 0, crypto: 0, index: 0, oil: 0, account_type_id: element.account_type_id });
        });

        nav_name_ib.push({ name, lastname, email, user_id })
        this.setState({ select_name_ib: e.target.value });
        this.setState({ data_tbl_manage: { ...data_tbl_manage, data_tbl: [...data_tbl_manage.data_tbl, { name, lastname, email, user_id, rebate }] } });
        this.handleCreateSelfRebate([...data_tbl_manage.data_tbl, { name, lastname, email, user_id, rebate }])
    }

    handleChangeEditRebate = (e, i, user_id) => {

        const { data_tbl_manage } = this.state;
        let name = e.target.name;
        let value = Number(e.target.value);

        let newData = data_tbl_manage.data_tbl.map(item => {
            if (item.user_id === user_id) {
                const updatedRebate = item.rebate.map((rebate, index) => {
                    if (index === i) {
                        return {
                            ...rebate,
                            [name]: value
                        };
                    }
                    return rebate;
                });
                return {
                    ...item,
                    rebate: updatedRebate
                };
            }
            return item;
        });

        this.setState({ data_tbl_manage: { ...data_tbl_manage, data_tbl: newData } });

    };

    handleChangeEditSelfRebate = (e, i, user_id) => {

        const { data_self_tbl } = this.state;
        let name = e.target.name;
        let value = Number(e.target.value);

        let newData = data_self_tbl.data_tbl.map(item => {
            if (item.user_id === user_id) {
                const updatedRebate = item.rebate.map((rebate, index) => {
                    if (index === i) {
                        return {
                            ...rebate,
                            [name]: value
                        };
                    }
                    return rebate;
                });
                return {
                    ...item,
                    rebate: updatedRebate
                };
            }
            return item;
        });

        this.setState({ data_self_tbl: { ...data_self_tbl, data_tbl: newData } });

    };

    handleChangeSave = (account_type_id) => {

        const { data_tbl_manage, nav_name_ib } = this.state;

        let data = data_tbl_manage.data_tbl;

        let campaign = nav_name_ib.map((e) => { return e.user_id });
        let forex = data.map((e) => e.rebate.find((ele) => ele.account_type_id === account_type_id).forex)
        let gold = data.map((e) => e.rebate.find((ele) => ele.account_type_id === account_type_id).gold)
        let crypto = data.map((e) => e.rebate.find((ele) => ele.account_type_id === account_type_id).crypto)
        let index = data.map((e) => e.rebate.find((ele) => ele.account_type_id === account_type_id).index)
        let oil = data.map((e) => e.rebate.find((ele) => ele.account_type_id === account_type_id).oil)

        let obj = [{
            user_id: Number(nav_name_ib.at(-1).user_id),
            account_type_id,
            forex: JSON.stringify(forex),
            gold: JSON.stringify(gold),
            crypto: JSON.stringify(crypto),
            index: JSON.stringify(index),
            oil: JSON.stringify(oil),
            header: Number(this.props.match.params.id),
            campaign: JSON.stringify(campaign),
            self_rebate: false,
        }]

        // console.log(...obj);
        if (obj) {
            this.handleAddCampaign(...obj);
        }

    };

    handleChangeSaveSelfRebate = (account_type_id) => {

        const { data_self_tbl, nav_name_ib } = this.state;

        let data = data_self_tbl.data_tbl;

        let forex = data.map((e) => e.rebate.find((ele) => ele.account_type_id === account_type_id).forex)
        let gold = data.map((e) => e.rebate.find((ele) => ele.account_type_id === account_type_id).gold)
        let crypto = data.map((e) => e.rebate.find((ele) => ele.account_type_id === account_type_id).crypto)
        let index = data.map((e) => e.rebate.find((ele) => ele.account_type_id === account_type_id).index)
        let oil = data.map((e) => e.rebate.find((ele) => ele.account_type_id === account_type_id).oil)

        let obj = [{
            user_id: Number(nav_name_ib.at(-1).user_id),
            account_type_id,
            forex: JSON.stringify(forex),
            gold: JSON.stringify(gold),
            crypto: JSON.stringify(crypto),
            index: JSON.stringify(index),
            oil: JSON.stringify(oil),
            header: Number(this.props.match.params.id),
            campaign: JSON.stringify([Number(nav_name_ib.at(-1).user_id)]),
            self_rebate: true,
        }]

        // console.log(...obj);
        if (obj) {
            this.handleAddCampaign(...obj);
        }

    };

    handleAddCampaign = async (obj) => {

        try {
            this.setState({ loading: true });
            let res = await POST(ADDCAMPAIGNDEV, obj);
            if (res.success) {
                this.setState({ loading: false });
                Swal.fire({
                    title: "สำเร็จ", text: `${res.result.message}`, icon: "success",
                    showConfirmButton: true
                })
            } else {
                this.setState({ loading: false });
                Swal.fire({
                    title: "ไม่สำเร็จ", text: "อัพเดทข้อมูลไม่สำเร็จ", icon: "warning",
                    showConfirmButton: true
                })
            }
        } catch (error) {
            console.log(error)
            this.setState({ loading: false });
        }
    }

    handleDeleteCampaign = (user_id, header, campaign, self_rebate) => {
        Swal.fire({
            text: `ต้องการลบรายการหรือไม่`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let res = await POST(DELETECAMPAIGNDEV, { user_id, header, campaign, self_rebate });
                    if (res.success) {
                        Swal.fire({
                            title: "สำเร็จ", text: `${res.result.message}`, icon: "success",
                            showConfirmButton: true
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                        })
                    } else {
                        Swal.fire({
                            title: "ไม่สำเร็จ", text: "ลบข้อมูลไม่สำเร็จ", icon: "warning",
                            showConfirmButton: true
                        })
                    }
                } catch (error) {
                    Swal.fire({
                        title: "Warning", text: error, icon: "warning",
                        showConfirmButton: true
                    });
                }
            }
        });
    };

    handleDeleteNav = (user_id) => {

        const { data_tbl_manage, nav_name_ib } = this.state;

        let newNav = nav_name_ib.filter((e) => e.user_id !== user_id)
        let data_tbl = data_tbl_manage.data_tbl.filter((e) => e.user_id !== user_id)

        if (newNav) {
            this.getSelectCampaign(newNav.at(-1).user_id)
        }

        this.setState({ data_tbl_manage: { ...data_tbl_manage, data_tbl }, nav_name_ib: newNav });
        this.handleCreateSelfRebate(data_tbl)

    };

    handleAction = (type) => {
        //action save or cancel
        if (type === 'save') {
            Swal.fire({
                title: "สำเร็จ", text: `บันทึกข้อมูลสำเร็จ`, icon: "success",
                showConfirmButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            })
        } else {
            Swal.fire({
                title: "ยกเลิกรายการ", icon: "warning",
                showConfirmButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        nav_name_ib: [],
                        data_select: [],
                        select_name_ib: '',
                        data_tbl_rebate: [],
                        data_tbl_manage: {
                            head_tbl: [],
                            data_tbl: [],
                        },
                        data_self_tbl: {
                            head_tbl: [],
                            data_tbl: [],
                        },
                        enable_add: false,
                        enable_edit: false,
                        enable_self_edit: false,
                    });
                    this.getIbAccountType();
                    this.getCampaign();
                }
            })
        }
    };

    handleSearch = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    submitSearch = async () => {
        const { search_text } = this.state;
        try {
            this.setState({ loading: true });
            let res = await POST(GETCAMPAIGNSEARCHDEV, { user_id: this.props.match.params.id, search_text });
            if (res.success) {
                this.setState({ data_tbl_rebate: res.result })
                this.setState({ loading: false });
            } else {
                Swal.fire({
                    title: "ไม่สำเร็จ", text: res.message, icon: "warning",
                    showConfirmButton: true
                });
                this.setState({ loading: false });
            }
        } catch (error) {
            Swal.fire({
                title: "ไม่สำเร็จ", text: error, icon: "warning",
                showConfirmButton: true
            });
            this.setState({ loading: false });
        }
    };

    handleCreateSelfRebate = (data) => {
        const { data_tbl_manage, data_self_tbl } = this.state;

        // console.log(data_tbl_manage);
        // this.setState({
        //     data_self_tbl: {
        //         head_tbl: [],
        //         data_tbl: [],
        //     },
        // })
        this.setState({ data_self_tbl: ({ data_tbl: [data.at(-1)], head_tbl: data_tbl_manage.head_tbl }) });
    };

    handleCopyRebate = () => {
        const { data_tbl_manage } = this.state;
        this.setState({ data_self_tbl: ({ data_tbl: [data_tbl_manage.data_tbl.at(-1)], head_tbl: data_tbl_manage.head_tbl }) });
    };

    render() {
        const language = localStorage.getItem("language");
        const { loading, page, select_name_ib, data_select, nav_name_ib, data_tbl_manage, enable_add, data_tbl_rebate, enable_edit, enable_self_edit, data_tbl_view, data_self_tbl } = this.state;
        return (
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <Loading open={loading} />
                <Grid>
                    <ListIBCampaign
                        language={language}
                        state={this.state}
                        handleChangePage={this.handleChangePage}
                        handleEnableAdd={this.handleEnableAdd}
                        handleChangeSelect={this.handleChangeSelect}
                        handleChangeEditRebate={this.handleChangeEditRebate}
                        handleChangeEditSelfRebate={this.handleChangeEditSelfRebate}
                        handleChangeSave={this.handleChangeSave}
                        handleChangeSaveSelfRebate={this.handleChangeSaveSelfRebate}
                        handleDeleteNav={this.handleDeleteNav}
                        handleEditCampaign={this.handleEditCampaign}
                        handleEditCampaignSelfRebate={this.handleEditCampaignSelfRebate}
                        handleAction={this.handleAction}
                        handleDeleteCampaign={this.handleDeleteCampaign}
                        handleSearch={this.handleSearch}
                        submitSearch={this.submitSearch}
                        handleViewCampaign={this.handleViewCampaign}
                        handleCloseView={this.handleCloseView}
                        handleCopyRebate={this.handleCopyRebate}
                        page={page}
                        data_tbl_manage={data_tbl_manage}
                        data_tbl_rebate={data_tbl_rebate}
                        data_tbl_view={data_tbl_view}
                        select_name_ib={select_name_ib}
                        data_select={data_select}
                        nav_name_ib={nav_name_ib}
                        enable_add={enable_add}
                        enable_edit={enable_edit}
                        enable_self_edit={enable_self_edit}
                        data_self_tbl={data_self_tbl}
                    />
                </Grid>
            </Container>
        );
    }
}

export default withRouter(IBCampaign);
