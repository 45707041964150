import React from "react";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from "chart.js";
import 'chartjs-adapter-moment';
import { Grid, Typography } from "@material-ui/core";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

export default function MonitorChart({ title, crm, database, period }) {
    const sortData = (data) => {
        return [...data].sort((a, b) => moment.utc(a.created_at).valueOf() - moment.utc(b.created_at).valueOf());
    };

    const sortedCRM = sortData(crm);
    const sortedDatabase = sortData(database);

    const groupByDayAndAverage = (data) => {
        const grouped = data.reduce((acc, item) => {
            const day = moment.utc(item.created_at).format("YYYY-MM-DD");
            if (!acc[day]) {
                acc[day] = { sum: 0, count: 0, size: 0, usage: 0, avaliable: 0, servers: new Set(), types: new Set() };
            }
            acc[day].sum += item.percent_use;
            acc[day].size += item.size;
            acc[day].usage += item.usage;
            acc[day].avaliable += item.avaliable;
            acc[day].count += 1;
            acc[day].servers.add(item.server); // เก็บค่าของเซิร์ฟเวอร์
            acc[day].types.add(item.type);     // เก็บค่าของประเภท

            return acc;
        }, {});

        return Object.keys(grouped).map(day => ({
            x: moment.utc(day, "YYYY-MM-DD").toDate(),
            y: grouped[day].sum / grouped[day].count,  // ค่าเฉลี่ย percent_use
            size: grouped[day].size / grouped[day].count,  // ค่าเฉลี่ยขนาด
            usage: grouped[day].usage / grouped[day].count, // ค่าเฉลี่ยการใช้งาน
            avaliable: grouped[day].avaliable / grouped[day].count, // ค่าเฉลี่ยที่เหลือ
            server: Array.from(grouped[day].servers).join(", "), // รวมค่าที่เป็นเซิร์ฟเวอร์
            type: Array.from(grouped[day].types).join(", ") // รวมค่าที่เป็นประเภท
        }));
    };

    // ใช้ฟังก์ชันจัดกลุ่มข้อมูลถ้า period เป็น 30 วัน
    const processedCRM = period === 30
        ? groupByDayAndAverage(sortedCRM)
        : sortedCRM.map(e => ({
            x: moment.utc(e.created_at).toDate(),
            y: e.percent_use,
            server: e.server,
            type: e.type,
            size: e.size,
            usage: e.usage,
            avaliable: e.avaliable
        }));

    const processedDatabase = period === 30
        ? groupByDayAndAverage(sortedDatabase)
        : sortedDatabase.map(e => ({
            x: moment.utc(e.created_at).toDate(),
            y: e.percent_use,
            server: e.server,
            type: e.type,
            size: e.size,
            usage: e.usage,
            avaliable: e.avaliable
        }));

    const getTimeUnit = (period) => {
        if (period === 1) return { unit: "hour", tooltipFormat: "HH:mm", displayFormat: "HH:mm" };
        if (period === 7) return { unit: "day", tooltipFormat: "dddd D MMM", displayFormat: "ddd D" };
        return { unit: "day", tooltipFormat: "D MMM", displayFormat: "D" };
    };

    const chartOptions = {
        responsive: true,
        scales: {
            x: {
                type: "time",
                time: {
                    unit: getTimeUnit(period).unit,
                    tooltipFormat: getTimeUnit(period).tooltipFormat,
                    displayFormats: {
                        hour: "HH:mm",
                        day: "ddd D MMM",
                        month: "D MMM",
                    },
                },
                ticks: {
                    autoSkip: true,
                }
            },
            y: {
                min: 0,
                max: 100,
                ticks: {
                    stepSize: 10,
                    callback: function (value) {
                        return `${value}%`;
                    }
                }
            }
        },
        plugins: {
            tooltip: {
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                titleColor: '#fff',
                bodyColor: '#fff',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                cornerRadius: 5,
                padding: 10,
                callbacks: {
                    label: function (tooltipItem) {
                        const value = tooltipItem.raw;
                        const time = moment(value.x).format("HH:mm, D MMM YYYY");
                        return [
                            `${tooltipItem.dataset.label}`,
                            `เวลา: ${time}`,
                            `ค่า: ${value.y.toFixed(2)}%`,
                            `เซิร์ฟเวอร์: ${value.server}`,
                            `ประเภท: ${value.type}`,
                            `size: ${value.size.toFixed(2)} GB`,
                            `usage: ${value.usage.toFixed(2)} GB`,
                            `avaliable: ${value.avaliable.toFixed(2)} GB`
                        ];
                    }
                }
            }
        }
    };

    // 🔹 Dataset สำหรับ CRM
    const monitor_crm = {
        labels: processedCRM.map(e => e.x),
        datasets: [
            {
                label: `${title} CRM`,
                data: processedCRM,
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)",
                borderWidth: 2,
            },
        ]
    };

    // 🔹 Dataset สำหรับ Database
    const monitor_database = {
        labels: processedDatabase.map(e => e.x),
        datasets: [
            {
                label: `${title} Database`,
                data: processedDatabase,
                fill: true,
                backgroundColor: "rgba(255,99,132,0.2)",
                borderColor: "rgba(255,99,132,1)",
                borderWidth: 2,
            },
        ]
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000", mr: 2, display: { xs: "block", md: "none" } }}>
                    {title} CRM
                </Typography>
                <Line data={monitor_crm} options={chartOptions} />
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: 18, fontWeight: 600, color: "#000", mr: 2, display: { xs: "block", md: "none" } }}>
                    {title} Database
                </Typography>
                <Line data={monitor_database} options={chartOptions} />
            </Grid>
        </Grid>
    );
}
