import { Autocomplete, Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import DatePicker from "react-date-picker";
import GridTableManager from "./GridTableManager";
import { headerTable, textFiled } from "functions/Static";
import GridPaperIB from "./GridPaperIB";
import { GridTextField } from "components";
import GridTableLot from "./GridTableLot";
import GridTableSaleManager from "./GridTableSaleManager";
import GridTableSaleManagerTeam from "./GridTableSaleManagerTeam";

const override = {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
};

const ManagerCard = (props) => {
    const {
        language,
        state,
        submitSearch,
        handleDateStartChange,
        handleDateEndChange,
        data_tbl,
        data_sale_manager_tbl,
        data_header,
        header,
        handleSearch,
        data_lot_all,
        data_lot_search,
        handleShowAll,
        showAll,
        getSaleAgentBySale,
        getUserSaleAllAgent,
        getUserSale,
        search_check,
        data_sale_manager_team_tbl,
        getSaleManagerAgentById,
        handleChangePage,
        pageSale,
        pageSaleManager,
        pageSaleManagerTeam,
        showAllSaleManager,
        getAgentSaleManagerAll,
        data_select,
        select_search,
        handleChangeSelect,
    } = props;

    const classes = useStyles();
    const role = localStorage.getItem("role");

    const countSale = Math.ceil(data_tbl.length > 0 && data_tbl.length / 10);
    const setPageSale = pageSale - 1;
    const dataListSale = data_tbl.slice(setPageSale * 10, pageSale * 10);

    const countSaleManager = Math.ceil(data_sale_manager_tbl.length > 0 && data_sale_manager_tbl.length / 10);
    const setPageSaleManager = pageSaleManager - 1;
    const dataListSaleManager = data_sale_manager_tbl.slice(setPageSaleManager * 10, pageSaleManager * 10);

    const countSaleManagerTeam = Math.ceil(data_sale_manager_team_tbl.length > 0 && data_sale_manager_team_tbl.length / 10);
    const setPageSaleManagerTeam = pageSaleManagerTeam - 1;
    const dataListSaleManagerTeam = data_sale_manager_team_tbl.slice(setPageSaleManagerTeam * 10, pageSaleManagerTeam * 10);

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <GridPaperIB data={header} header={headerTable.sale_header_main} />
            </Grid>
            <Grid item xs={12}>
                <GridTableLot
                    body={data_lot_all}
                // body={[]}
                />
            </Grid>
            <Grid item container spacing={2} alignItems="flex-end">
                <Grid item xs={12} sm={3}>
                    <Typography variant="body1">วันที่เริ่ม</Typography>
                    <DatePicker className={"date-picker"} onChange={handleDateStartChange} value={state.date_start} format="dd/MM/yyyy" />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant="body1">วันที่สิ้นสุด</Typography>
                    <DatePicker className={"date-picker"} onChange={handleDateEndChange} value={state.date_end} format="dd/MM/yyyy" />
                </Grid>
                <Grid item xs={8} sm={5}>
                    {/* <GridTextField
                        data={textFiled.search_text}
                        state={state}
                        handleChange={handleSearch}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                submitSearch();
                            }
                        }}
                    /> */}
                    <Autocomplete
                        fullWidth
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : `(${option.role === 'sale_manager' ? 'Sale Manager' : 'Sale'}) ${option.name} ${option.lastname}`
                        }
                        options={data_select.filter(e => e.admin_id !== 0)}
                        value={select_search}
                        freeSolo
                        onChange={(event, value) => handleChangeSelect(value, 'select_search')}
                        renderInput={(params) => <TextField {...params} placeholder="ค้นหา" />}
                    />
                </Grid>
                <Grid item xs={4} sm={1}>
                    <Button
                        fullWidth
                        variant="contained"
                        style={{ backgroundColor: "#4589C6" }}
                        sx={{ fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                        onClick={submitSearch}
                    >
                        {language === "th" ? "ค้นหา" : "Search"}
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <GridPaperIB data={data_header} header={headerTable.sale_header} />
            </Grid>
            <Grid item xs={12}>
                <GridTableLot
                    body={data_lot_search}
                // body={[]}
                />
            </Grid>
            <Grid item xs={12}>
                <GridTableSaleManagerTeam
                    head={headerTable.saleManagerTeam_manager}
                    count={countSaleManagerTeam}
                    page={pageSaleManagerTeam}
                    body={dataListSaleManagerTeam}
                    date_start={state.date_start}
                    date_end={state.date_end}
                    handleChangePage={handleChangePage}
                    language={language}
                    // handleShowAll={handleShowAll}
                    // showAll={showAll}
                    getSaleManagerAgentById={getSaleManagerAgentById}
                    // getUserSaleAllAgent={getUserSaleAllAgent}
                    getUserSale={getUserSale}
                    search_check={search_check}
                />
            </Grid>
            <Grid item xs={12}>
                <GridTableSaleManager
                    head={headerTable.saleManagerList_manager}
                    count={countSaleManager}
                    page={pageSaleManager}
                    body={dataListSaleManager}
                    date_start={state.date_start}
                    date_end={state.date_end}
                    handleChangePage={handleChangePage}
                    language={language}
                    handleShowAll={handleShowAll}
                    showAll={showAllSaleManager}
                    getSaleAgentBySale={getSaleAgentBySale}
                    getAgentSaleManagerAll={getAgentSaleManagerAll}
                    getUserSale={getUserSale}
                    search_check={search_check}
                />
            </Grid>
            <Grid item xs={12}>
                <GridTableManager
                    head={headerTable.managerList}
                    count={countSale}
                    page={pageSale}
                    body={dataListSale}
                    date_start={state.date_start}
                    date_end={state.date_end}
                    handleChangePage={handleChangePage}
                    language={language}
                    handleShowAll={handleShowAll}
                    showAll={showAll}
                    getSaleAgentBySale={getSaleAgentBySale}
                    getUserSaleAllAgent={getUserSaleAllAgent}
                    getUserSale={getUserSale}
                    search_check={search_check}
                />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    paper: {
        padding: 20,
    },
}));
export default ManagerCard;
