import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { tofieds } from "functions/Functions";
const theme = createMuiTheme();

const GridTableLot = (props) => {
    const classes = useStyles();
    const sub_head = [{ label: "Forex" }, { label: "Gold" }, { label: "Crypto" }, { label: "Index" }, { label: "Oil" }];
    const sub_body = [{ label: "forex" }, { label: "gold" }, { label: "crypto" }, { label: "index" }, { label: "oil" }];

    return (
        <Grid className="table-btn" my={3}>
            < TableContainer >
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" rowSpan={2}>
                                <Typography noWrap>ประเภทบัญชี</Typography>
                            </TableCell>
                            <TableCell align="center" colSpan={5}>
                                <Typography noWrap>จำนวน Lot</Typography>
                            </TableCell>
                            <TableCell align="center" rowSpan={2}>
                                <Typography noWrap>รวม</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {sub_head.map((e, ind) => {
                                return (
                                    <TableCell key={ind} align="center" style={{ minWidth: 100 }}>
                                        <Typography noWrap>{e.label}</Typography>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.body.length > 0 && (
                                <>
                                    {/* Render body rows */}
                                    {props.body.map((e, ind) => {
                                        // Calculate row total
                                        const rowTotal = sub_body.reduce((total, el) => total + Number(e[el.label] || 0), 0);

                                        return (
                                            <TableRow key={ind}>
                                                <TableCell>
                                                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                        {e.account_type_name}
                                                    </Typography>
                                                </TableCell>
                                                {sub_body.map((el, subInd) => (
                                                    <TableCell key={subInd} align="right">
                                                        <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                            {Number(e[el.label]).toFixed(2)}
                                                        </Typography>
                                                    </TableCell>
                                                ))}
                                                {/* Display row total */}
                                                <TableCell align="right" style={{ fontWeight: "bold", backgroundColor: "#eeeeee" }}>
                                                    {rowTotal.toFixed(2)}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                    {/* Render sum row (total row) */}
                                    <TableRow style={{ backgroundColor: "#eeeeee" }}>
                                        <TableCell align="left" style={{ fontWeight: "bold" }}>
                                            รวม
                                        </TableCell>
                                        {sub_body.map((el, subInd) => {
                                            // Sum values for each column
                                            const columnTotal = props.body.reduce((accumulator, e) => accumulator + Number(e[el.label] || 0), 0);
                                            return (
                                                <TableCell key={subInd} align="right" style={{ fontWeight: "bold" }}>
                                                    {columnTotal.toFixed(2)}
                                                </TableCell>
                                            );
                                        })}
                                        {/* Display total sum for the row */}
                                        <TableCell align="right" style={{ fontWeight: "bold", backgroundColor: "#e0e0e0" }}>
                                            {props.body.reduce((accumulator, e) => {
                                                return accumulator + sub_body.reduce((subAcc, el) => subAcc + Number(e[el.label] || 0), 0);
                                            }, 0).toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                </>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justifyContent="center" mt={5}>
                {props.body.length === 0 && (
                    <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                        {props.language === "th" ? "ไม่มีข้อมูลในตาราง" : "No data available in table"}
                    </Typography>
                )}
                {/* {props.body.length > 0 && <Pagination count={props.count} page={props.page} onChange={props.handleChangePage} variant="outlined" color="secondary" />} */}
            </Grid>
        </Grid >
    );
};
const useStyles = makeStyles(() => ({
    table: {
        minWidth: 400,
    },
    fontStyleTitle: {
        fontSize: 18,
        fontWeight: 600,
        color: "#000",
    },
    fontStyleNav: {
        fontSize: 13,
        fontWeight: "bold",
        color: "#000",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            fontSize: 18,
        },
    },
    fontStyleDetail: {
        fontSize: 13,
        color: "#000",
        // textAlign: "justify",
        [theme.breakpoints.up("md")]: {
            fontSize: 18,
        },
        marginTop: 10,
        marginBottom: 10,
    },
    fontStyle: {
        fontSize: 13,
        color: "#000",
        [theme.breakpoints.up("md")]: {
            fontSize: 18,
        },
        display: "flex",
        justifyContent: "center",
    },
}));
export default GridTableLot;
