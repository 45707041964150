import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { makeStyles } from "@material-ui/styles";
import { GridTextField } from "components";
import { headerTable, textFiled } from "functions/Static";
import { withRouter } from "react-router-dom";
import GridTableListCampaign from "./GridTableListCampaign";
import GridTableManage from "./GridTableManage";
import GridTableView from "./GridTableView";

const theme = createMuiTheme();

const ListIBCampaign = (props) => {
    const {
        language,
        state,
        handleChangePage,
        handleEnableAdd,
        handleChangeSelect,
        handleChangeEditRebate,
        handleChangeEditSelfRebate,
        handleChangeSave,
        handleChangeSaveSelfRebate,
        handleDeleteNav,
        handleEditCampaign,
        handleEditCampaignSelfRebate,
        handleAction,
        handleDeleteCampaign,
        handleSearch,
        handleViewCampaign,
        handleCloseView,
        submitSearch,
        handleCopyRebate,
        page,
        select_name_ib,
        data_select,
        nav_name_ib,
        data_tbl_manage,
        data_tbl_rebate,
        data_tbl_view,
        enable_add,
        enable_edit,
        enable_self_edit,
        data_self_tbl,
    } = props;

    const classes = useStyles();
    const count = Math.ceil(data_tbl_rebate.length > 0 && data_tbl_rebate.length / 10);
    const setPage = props.page - 1;
    const dataList = data_tbl_rebate.slice(setPage * 10, props.page * 10);
    const role = localStorage.getItem("role");
    const nav_length = nav_name_ib.length;

    return (
        <Grid container rowSpacing={5} alignItems="center">
            <Grid item xs={12} sx={{ borderBottom: "solid 2px #4589C6" }}>
                <Stack width="100%" direction="row" display="flex" justifyContent="space-between" my={1}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000" }}>IB Rebate Campaign</Typography>
                    {(!enable_add && !enable_edit && !enable_self_edit && !state.view_campaign && role === "admin") &&
                        <Button
                            variant="outlined"
                            sx={{ fontSize: 16, fontWeight: 600 }}
                            disabled={enable_add}
                            onClick={handleEnableAdd}
                        >
                            + Add
                        </Button>
                    }
                    {(enable_add || enable_edit || enable_self_edit) &&
                        <Stack direction="row" spacing={3}>
                            <Button
                                variant="outlined"
                                sx={{ fontSize: 16, fontWeight: 600 }}
                                onClick={() => handleAction('save')}
                            >
                                save
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{ fontSize: 16, fontWeight: 600 }}
                                onClick={() => handleAction('cancel')}
                            >
                                cancel
                            </Button>
                        </Stack>
                    }
                </Stack>
            </Grid>
            {enable_edit &&
                <Grid item xs={12}>
                    <Stack direction="row" display="flex" alignItems="center" >
                        {props.nav_name_ib.map((e, i) =>
                            <Typography key={i + 1} className={classes.fontStyleNav}  >
                                {e.name} {e.lastname}{nav_length - (i + 1) > 0 && ` > `}
                            </Typography>
                        )}
                    </Stack>
                </Grid>
            }

            {(enable_add) &&
                <>
                    <Grid item xs={5}>
                        <FormControl fullWidth>
                            <InputLabel >เลือกชื่อ IB</InputLabel>
                            <Select
                                id="ib_rebate_campaign"
                                value={select_name_ib}
                                label="เลือกชื่อ IB"
                                onChange={handleChangeSelect}
                            >
                                <MenuItem value="" disabled>เลือกชื่อ IB</MenuItem>
                                {data_select.map((e, i) =>
                                    <MenuItem key={i} value={e} disabled={!e.ib_status}>{e.name} {e.lastname} ({e.email})</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" display="flex" alignItems="center" >
                            {props.nav_name_ib.map((e, i) =>
                                <Typography key={i + 1} className={classes.fontStyleNav}  >
                                    {e.name} {e.lastname} &nbsp;{nav_length - (i + 1) > 0 && `>`}&nbsp;{((i + 1) !== 1) && (nav_length === (i + 1)) && <CloseRoundedIcon onClick={() => handleDeleteNav(e.user_id)} sx={{ cursor: "pointer" }} />}
                                </Typography>
                            )}
                        </Stack>
                    </Grid>
                </>
            }
            {(enable_add || enable_edit) &&
                <>
                    <Grid item xs={12}>
                        <GridTableManage body={data_tbl_manage.data_tbl} data_tbl_manage={data_tbl_manage} handleChangeEditRebate={handleChangeEditRebate} handleChangeSave={handleChangeSave} />
                    </Grid>
                    < Grid item xs={12}>
                        <Button
                            variant="outlined"
                            sx={{ fontSize: 16, fontWeight: 600, my: 2 }}
                            onClick={handleCopyRebate}
                        >
                            Copy Rebate
                        </Button>
                        < GridTableManage body={data_self_tbl.data_tbl} data_tbl_manage={data_self_tbl} handleChangeEditRebate={handleChangeEditSelfRebate} handleChangeSave={handleChangeSaveSelfRebate} />
                    </Grid>
                </>
            }
            {enable_self_edit &&
                <Grid item xs={12}>
                    < GridTableManage body={data_self_tbl.data_tbl} data_tbl_manage={data_self_tbl} handleChangeEditRebate={handleChangeEditSelfRebate} handleChangeSave={handleChangeSaveSelfRebate} />
                </Grid>
            }
            {
                (state.view_campaign) &&
                <>
                    <Grid item xs={8}>
                        <Stack direction="row" display="flex" alignItems="center" >
                            {props.nav_name_ib.map((e, i) =>
                                <Typography key={i + 1} className={classes.fontStyleNav}  >
                                    {e.name} {e.lastname} &nbsp;{nav_length - (i + 1) > 0 && `>`}&nbsp;{((i + 1) !== 1) && (nav_length === (i + 1))}
                                </Typography>
                            )}
                        </Stack>
                    </Grid>
                    <Grid item xs={4} display="flex" justifyContent="flex-end">
                        <Button
                            variant="outlined"
                            sx={{ fontSize: 16, fontWeight: 600 }}
                            onClick={handleCloseView}
                        >
                            Close
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <GridTableView body={data_tbl_view.data_tbl} data_tbl_view={data_tbl_view} handleChangeEditRebate={handleChangeEditRebate} handleChangeSave={handleChangeSave} />
                    </Grid>
                </>
            }
            <Grid item xs={12}>
                <Stack direction="row" alignItems="center" mt={2}>
                    <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2 }}>Search:</Typography>
                    <Grid container sx={{ width: "40vw", mr: 2 }}>
                        <GridTextField
                            data={textFiled.search_text}
                            state={state}
                            handleChange={handleSearch}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    submitSearch();
                                }
                            }}
                        />
                    </Grid>
                    <Button
                        variant="contained"
                        style={{ backgroundColor: "#4589C6" }}
                        sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40 }}
                        onClick={submitSearch}
                    >
                        {language === "th" ? "ค้นหา" : "Search"}
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <GridTableListCampaign
                    role={role}
                    head={headerTable.ib_rebate_list_campaign}
                    body={dataList} count={count}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleEditCampaign={handleEditCampaign}
                    handleEditCampaignSelfRebate={handleEditCampaignSelfRebate}
                    enable_add={enable_add}
                    handleDeleteCampaign={handleDeleteCampaign}
                    handleViewCampaign={handleViewCampaign}
                />
            </Grid>
        </Grid >
    );
};

const useStyles = makeStyles(() => ({
    fontStyleNav: {
        display: "flex",
        alignItems: "center",
        fontSize: 13,
        fontWeight: "bold",
        color: "#000",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            fontSize: 18,
        },
    },
}));

export default withRouter(ListIBCampaign);
